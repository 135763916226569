export const userType = {
  super: "uh#jb@sk",
  changed: "786788",
  center: "center",
};

export const USER_SIGN_UP = "USER_SIGN_UP";
export const UserSignUp = (data) => ({
  type: USER_SIGN_UP,
  payload: data,
});

export const USER_LOG_OUT = "USER_LOG_OUT";
export const UserLogOut = () => ({
  type: USER_LOG_OUT,
});

export const USER_CHANGE_USER_TYPE = "USER_CHANGE_USER_TYPE";
export const UserChangeUserType = (data) => ({
  type: USER_CHANGE_USER_TYPE,
  payload: data,
});

export const USER_TYPE_ALL_DATA = "USER_TYPE_ALL_DATA";
export const UserTypeAllData = (data) => ({
  type: USER_TYPE_ALL_DATA,
  payload: data,
});
