export const getDesignTheme = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            light: "#ffa726",
            main: "#EA6823",
            dark: "#fb8c00",
          },
          secondary: {
            light: "#49a3f1",
            main: "#3080e9",
            dark: "#146fe7",
          },
          contras: {
            main: "#000000",
          },
          disable: {
            light: "#bdbdbd",
            main: "#9e9e9e",
            dark: "#616161",
          },
          current: {
            main: "#ffffff",
          },
          background: {
            default: "#f0f2f5",
          },
          text: {
            contrasPrimary: "#ffffff",
            contrasSecondary: "#ffffff88",
          },
          border: {
            primary: "#e1e1e1",
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#EA6823",
          },
          secondary: {
            main: "#000",
          },
          background: {
            default: "#f1f1f1",
          },
        }),
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  selected: {},
});
