import {
  createTheme,
  CssBaseline,
  Grid,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import React, { lazy, Suspense, useMemo, useState } from "react";
import { Provider } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import store from "../Redux/store.js";
import { getDesignTheme } from "./Theme/DesignTheme";
import "react-toastify/dist/ReactToastify.css";


// components importing using lazy
const ErrorPage = lazy(() => import("../Components/Error/ErrorPage"));
const LoginPage = lazy(() => import("../Components/Login/LoginPage"));
const HomeLayout = lazy(() => import("../Layout/Home"));

//get system preference
const InitialValue = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  let mode = prefersDarkMode ? "dark" : "light";
  return mode;
};

function App() {
  const [mode, setMode] = useState(InitialValue());

  const theme = useMemo(
    () => responsiveFontSizes(createTheme(getDesignTheme("light"))),
    [mode]
  );

  return (
    <Grid sx={{ m: 0, p: 0 }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Grid>Loading ...</Grid>}>
            <CssBaseline />
            {/* Pages Routes */}
            <BrowserRouter>
              <Grid
                sx={{
                  width: "100%",
                  minWidth: "980px",
                  height: "100vh",
                }}
              >
                <Routes>
                  <Route path="login" element={<LoginPage />} />
                  <Route path="home/*" element={<HomeLayout />} />
                  <Route path="error" element={<ErrorPage />} />
                  <Route path="" element={<Navigate replace to="/login" />} />
                  <Route path="*" element={<Navigate replace to="/error" />} />
                </Routes>
              </Grid>
              <ToastContainer />
            </BrowserRouter>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </Grid>
  );
}

export default App;
