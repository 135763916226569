import {
  USER_CHANGE_USER_TYPE,
  USER_LOG_OUT,
  USER_SIGN_UP,
  USER_TYPE_ALL_DATA,
} from "./UserAction";

const initialState = {
  user: {},
  type: "",
  typeUser: {},
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGN_UP:
      return { ...state, user: action.payload };
    case USER_LOG_OUT:
      return initialState;
    case USER_CHANGE_USER_TYPE:
      return { ...state, type: action.payload };
    case USER_TYPE_ALL_DATA:
      return { ...state, typeUser: action.payload };
    default:
      return state;
  }
};

export default UserReducer;
