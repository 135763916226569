export const AllPage = {
  p1: "dashboard",
  p2: "manage user",
  p3: "manage training",
  p4: "about nhrdf",
  p5: "all products",
  p6: "manage centre",
  p8: "manage Seeds ",
  p9: "All orders",
  p10: "registered training",
  p11: "feedbacks",
  p12: "All Seeds",
  p13: "Manage Services",
  p14: " Manage Gallery",
};

export const COMP_CHANGE_PAGE = "COMP_CHANGE_PAGE";
export const CompChangePage = (data) => ({
  type: COMP_CHANGE_PAGE,
  payload: data,
});

export const COMP_CHANGE_SIDE = "COMP_CHANGE_SIDE";
export const CompChangeSide = (data) => ({
  type: COMP_CHANGE_SIDE,
  payload: data,
});
