import { AllPage, COMP_CHANGE_PAGE, COMP_CHANGE_SIDE } from "./CompAction";

const initialState = {
  page: AllPage.p1,
  side: true,
};

const CompReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMP_CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case COMP_CHANGE_SIDE:
      return {
        ...state,
        side: action.payload,
      };
    default:
      return state;
  }
};

export default CompReducer;
