import { combineReducers } from "redux";
import UserReducer from "./User/UserReducer";
import CompReducer from "./Comp/CompReducer";
import DataReducer from "./Data/DataReducer";

const RootReducer = combineReducers({
  user: UserReducer,
  comp: CompReducer,
  data: DataReducer,
});

export default RootReducer;
