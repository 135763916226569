import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./RootReducer";

const middleware = [thunk];

export const LocalStoreName = "persistState";

function saveToLocalStorage(state) {
  try {
    const serialState = JSON.stringify(state);
    localStorage.setItem(LocalStoreName, serialState);
  } catch (e) {
    console.warn(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serialState = localStorage.getItem(LocalStoreName);
    if (serialState === null) return undefined;
    return JSON.parse(serialState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

const store = createStore(
  rootReducer,
  loadFromLocalStorage(),
  applyMiddleware(...middleware)
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
