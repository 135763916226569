import { DATA_SAVE_IN_REDUX } from "./DataAction";

const initialState = {
  loading: false,
};

const DataReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_SAVE_IN_REDUX:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default DataReducer;
